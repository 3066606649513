import React from 'react'
import './HeroSec.scss'
const HeroSec = () => {
    return (
        <>
            <div className='herosec flex items-center bg-[#1C2639] px-52 py-20'>
                <div className='flex flex-col gap-8 w-[60%] herosec-text'>
                    <h1>Your Premier Destination for Press Release Distribution</h1>
                    <p>Ensure your press release gains visibility across leading news and media platforms through our top-tier distribution service.</p>
                    <div className='flex items-center gap-3'>
                        <a href="/ContactUs" target="_blank" rel="noopener noreferrer"><button className='px-5 py-2 border-white border-[2px] rounded-[5px] bg-white hover:bg-[#1C2639] hover:text-white transition-all'>Book a Demo</button></a>
                        <a href="https://portal.thrivenewswire.com/login" target="_blank" rel="noopener noreferrer"><button className='px-5 py-2 border-white border-[2px] rounded-[5px] text-white'>Get Started</button></a>
                    </div>
                </div>
                <div className='w-1/2 herosec-img'>
                    <iframe src="https://www.youtube.com/embed/iUPJmI84PXs?autoplay=1&mute=1&si=vBnKWkzRjkWM4PR0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </>
    )
}

export default HeroSec
