import React, { useState } from 'react';
import './FAQ.scss';
import faqImg from '../../Assets/faq.jpg';
import uparrow from '../../Assets/uparrow.svg';
import downarrow from '../../Assets/down arrow.svg';

function FAQItem({ question, answer, isOpen, toggleOpen }) {
    const [headingBg, setHeadingBg] = useState(false);
    const [buttonRotation, setButtonRotation] = useState(false);

    const toggleContent = () => {
        toggleOpen();
        setHeadingBg(!headingBg);
        setButtonRotation(!buttonRotation);
    };

    return (
        <div className='w-[95%] border-[1px] border-[#1C263996] rounded-[5px]'>
            <div onClick={toggleContent} className={`faq-quest flex items-center justify-between p-4 ${isOpen ? 'bg-[#1C2639] text-white' : 'bg-none text-[#001D18]'}`}>
                <h1>{question}</h1>
                <img src={isOpen ? uparrow : downarrow} alt="" className='w-[15px] h-[15px]' />
            </div>
            <div className={`faq-para p-4 ${isOpen ? 'visible' : 'hidden'}`}>
                <p>{answer}</p>
            </div>
        </div>
    );
}

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleOpen = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqData = [
        {
            question: 'What sets ThriveNewsWire apart from others?',
            answer: "At ThriveNewsWire, your message takes center stage. We're a premier news distribution platform, connecting you with a vast network of top-tier media outlets to reach your both local and global audience. Leverage our market-focused solutions to engage with the communities that matter most, propel your story forward."
        },
        {
            question: 'How can we ensure that our press release has been published on leading news websites?',
            answer: "Transparency matters! After your press release lands, we send you a comprehensive proof of distribution report with advanced analytics. This detailed report features clickable links, revealing the exact top news sites and esteemed platforms where your message shines. Rest assured, you'll see the impact your story makes across the media landscape."
        },
        {
            question: 'Which major media outlets will feature my press releases?',
            answer: "Definitely! At ThriveNewsWire, we guarantee your press releases gain extensive exposure across major news and media outlets. Think renowned platforms like Yahoo News, Yahoo Finance, Bloomberg, FOX Network, MarketWatch, Benzinga, Nasdaq, International Business Times, and AP News, just to name a few. We connect your message with target audiences on a global scale, ensuring your story reaches the right eyes and ears."
        },
        {
            question: 'What is the usual timeline for the publication of my press release?',
            answer: "Once your press release passes our expert editorial review and update, it typically reaches major news and media outlets within 2 to 3 hours. Need quicker exposure? We offer urgent distribution options for even faster delivery. And to track your impact, we'll provide a detailed report with live links within 24 to 48 hours. ThriveNewsWire: get your message heard and seen, quickly and clearly."
        },
        {
            question: 'Which kinds of press releases are deemed unacceptable?',
            answer: "ThriveNewsWire champions journalistic integrity and upholds rigorous editorial guidelines. Every press release we distribute must adhere to these standards, ensuring accuracy, ethical conduct, and professionalism. We reject content that is false, misleading, unethical, defamatory, threatening, obscene, offensive, hateful, pornographic, profane, discriminatory, gambling-related, or promotes illegal activities. Together, we foster a space for truthful, respectful, and impactful communication. Review our detailed guidelines to learn more and join us in elevating the press release landscape."
        },
        {
            question: 'Does ThriveNewsWire offer Writing Services?',
            answer: "Yes, we definitely offer writing services! In fact, for our existing clients, we provide press release writing with a team of skilled and experienced writers. If you're interested in learning more, please contact us at media@thrivenewswire.com."
        },
    ];

    return (
        <>
            <div className='flex items-center justify-center px-52 mb-24 py-24 bg-[#1C263912] gap-4 faq-main'>
                <div className='flex flex-col items-center gap-10 w-1/2 faq-upper'>
                    <div className='FAQ-heading'>
                        <h1>FAQs</h1>
                    </div>
                    <div className='flex flex-col gap-4'>
                        {faqData.map((item, index) => (
                            <FAQItem
                                key={index}
                                question={item.question}
                                answer={item.answer}
                                isOpen={openIndex === index}
                                toggleOpen={() => toggleOpen(index)}
                            />
                        ))}
                    </div>
                </div>
                <div className='w-[40%] flex items-center faq-lower'>
                    <img src={faqImg} alt="" />
                </div>
            </div>
        </>
    );
}

export default FAQ;
