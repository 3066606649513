import React from 'react'

const Editorial = () => {
    return (
        <>
            <div className='flex flex-col items-start justify-center px-52 privacy-template gap-3 pt-5 pb-40'>
                <div>
                    <h1>
                        ThriveNewsWire Terms and Conditions
                    </h1>
                </div>
                <div>
                    <p>
                        The guidelines provided by ThriveNewsWire are crucial for ensuring that press releases meet the necessary standards for distribution. Here’s a summary of the key points:
                    </p>
                </div>
                <div>
                    <h3>
                        Acceptable Content:

                    </h3>
                    <p>
                        Press releases should contain a clear, timely, and newsworthy angle, avoiding advertising hype, direct address, and spam. <br />
                        <br />
                        Common news angles include new product or service launches, business expansions, organizational milestones, and expert opinions on current topics.
                    </p>
                </div>
                <div>
                    <h3>
                        Legal Press Release:
                    </h3>
                    <p>
                        Legal-related press releases must include sufficient documentation such as a case number or court of record.
                    </p>
                </div>
                <div>
                    <h3>
                        Contact Information:
                    </h3>
                    <p>
                        Press releases should provide valid contact details, including a name, phone number, and email address.
                    </p>
                </div>
                <div>
                    <h3>
                        PR Length:
                    </h3>
                    <p>
                        Press releases should be between 400 and 900 words, with a concise news summary of 1 or 2 sentences.
                    </p>
                </div>
                <div>
                    <h3>
                        Grammar and Spelling:
                    </h3>
                    <p>
                        Press releases must be free of spelling and grammatical errors, written entirely in English, and avoid excessive jargon or acronyms without definitions.
                    </p>
                </div>
                <div>
                    <h3>
                        Press Release Formatting:
                    </h3>
                    <p>
                        Press releases should not contain HTML tags, non-standard characters, or be written in all capital letters. Functional links are required.
                    </p>
                </div>
                <div>
                    <h3>
                        News Source:
                    </h3>
                    <p>
                        A clear news source must be identified in the headline, indicating the organization issuing the release.
                    </p>
                </div>
                <div>
                    <h3>
                        Accepted File Types:
                    </h3>
                    <p>
                        Releases may contain links or attachments with extensions such as .jpeg, .png, .pdf, and .doc. <br />
                        <br />
                        In addition to these guidelines, ThriveNewsWire does not distribute certain types of content, including unlisted crypto-related releases, advertisements, fabricated information, sexually explicit content, spam links, duplicate content, and content intended to harm individuals or groups.<br />
                        <br />
                        Further exclusions encompass unauthorized ticker symbols, gambling or casino-related content, loans, online pharmaceuticals, unsafe weight loss products, unapproved or misleading health supplements, sexual enhancement products, easy money schemes, stock recommendations, and BlackHat SEO tactics.<br />
                        <br />
                        Adhering to these guidelines ensures that press releases meet the necessary criteria for distribution through ThriveNewsWire’s platform and maintain the integrity and quality of the content provided.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Editorial
