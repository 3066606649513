import React from 'react';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import './Navbar.scss';

import thriveLogo from '../../Assets/Logolight.png';
import { useNavigate } from 'react-router-dom';

export default function TemplateDemo() {
  const navigate = useNavigate();

  const itemRenderer = (item) => (
    <a className="flex align-items-center p-menuitem-link">
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
    </a>
  );

  const items: MenuItem[] = [
    {
      label: 'Home',
      command: () => navigate('/')
    },
    {
      label: 'Pricing',
      command: () => navigate('/Pricing')
    },
    {
      label: 'Newsrooms',
      command: () => navigate('/Newsroom')
    },
    {
      label: 'Blogs',
      command: () => navigate('/Blogs')
    },
    {
      label: 'About Us',
      items: [
        {
          label: 'Who we are',
          command: () => navigate('/WhoWeAre')
        },
        {
          label: 'What we do',
          command: () => navigate('/WhatWeDo')
        },
      ]
    },
    {
      label: 'Contact Us',
      command: () => navigate('/ContactUs')
    },
    {
      label: 'Dashboard',
      command: () => window.open('https://portal.thrivenewswire.com/', '_blank'),
      className: 'dashboard-button'
    },
  ];

  const start = <a href="/"><img alt="logo" src={thriveLogo} height="40" className="mr-2" /></a>;

  return (
    <div className="card bg-[#1C2639] px-10 py-5">
      <Menubar model={items} start={start} />
    </div>
  )
}
