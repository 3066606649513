import React, { useState } from 'react'
import './Pricing.scss'
import PricingComponent from './PricingComponent.tsx'
import check from '../../Assets/check.png'
import cross from '../../Assets/cross.png'
const Pricing = () => {
    const starter = [

        {
            title: 'Basic',
            price: '$299',
            buttonLink: 'https://buy.stripe.com/eVa9Ei6E09D1cUg6pR',
            features: [
                { included: 'N/A' },
                { included: '100 News' },
                { included: 'Within 24 Hours' },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: cross },
            ]
        }
    ]

    const financial = [
        {
            title: 'Standard',
            price: '$399',
            buttonLink: 'https://buy.stripe.com/3cs03IbYkaH5g6seWo',
            features: [
                { included: '100k Views' },
                { included: '230 Syndication' },
                { included: 'Same Day' },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: cross },
                { included: cross },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: cross },
                { included: cross },
            ]
        }
    ]

    const corporate = [
        {
            title: 'Premium',
            price: '$499',
            buttonLink: 'https://buy.stripe.com/cN203I7I46qP7zWeWp',
            features: [
                { included: '150k Views' },
                { included: '230 Syndication' },
                { included: 'Same Day' },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
                { included: check },
            ]
        }
    ]

    const features = [
        {
            feature: 'Guaranteed Views'
        },
        {
            feature: 'Published on'
        },
        {
            feature: 'Distribution'
        },
        {
            feature: 'Street Insider'
        },
        {
            feature: 'AP News'
        },
        {
            feature: 'Benzinga'
        },
        {
            feature: 'Digital Journal'
        },
        {
            feature: 'Google News'
        },
        {
            feature: 'International Business Times'
        },
        {
            feature: 'Investor Observer'
        },
        {
            feature: 'Canadian Insider'
        },
        {
            feature: 'SEO Optimized released'
        },
        {
            feature: 'No Word Limit & Multiple buttonLinks'
        },
        {
            feature: 'Journalists'
        },
        {
            feature: 'Embed Video (Add On)'
        },
        {
            feature: 'Embedded Stock Symbol'
        },
        {
            feature: 'Analytics'
        },
        {
            feature: 'Europe'
        },
        {
            feature: 'Middle East'
        },
        {
            feature: 'Canada'
        },
        {
            feature: 'Latin America'
        },
        {
            feature: 'Africa'
        },
        {
            feature: 'Asia'
        }
    ];


    interface FormState {
        fullName: string;
        workEmail: string;
        company: string;
        phoneNumber: string;
        country: string;
        message: string;
    }

    const [formState, setFormState] = useState<FormState>({
        fullName: '',
        workEmail: '',
        company: '',
        phoneNumber: '',
        country: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Submit form logic here
        console.log(formState);
    };

    const [selectedPlan, setSelectedPlan] = useState('Starter')

    const handlePlanChange = (plantype) => {
        setSelectedPlan(plantype)
    }

    return (
        <>
            <div className='flex flex-col items-center justify-center mb-20'>
                <div className='pricing-hero bg-[#1C2639] flex flex-col items-center justify-center w-full py-20 px-[30px]'>
                    <h1>ThriveNewsWire Products and Packages</h1>
                    <p>Choose a plan to discover a solution for today’s PR and Corporate communications</p>
                </div>
                <div className='pay-button my-20'>
                    <button className='px-6 py-5 rounded-[10px] bg-black text-white'>Pay as you Go</button>
                </div>
                <div className='flex justify-center pricing-plans-web'>
                    <div className='flex flex-col items-start justify-center pricing-header bg-[#DDDDDD] w-[25%]'>
                        <div className='border-[1px] border-white p-[26px] pricing-header-inner'>
                            <h1>Features</h1>
                            <p>Choose the package which suits you the best and leave the rest to us!</p>
                        </div>
                        <div className='flex flex-col w-full'>
                            {features.map((data, index) => (
                                <div key={index} className='border-[1px] border-white flex items-center justify-center h-[62px] pricing-feature'>
                                    <h2>{data.feature}</h2>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <PricingComponent plans={starter} index={1} />
                    </div>
                    <div>
                        <PricingComponent plans={financial} index={2} />
                    </div>
                    <div>
                        <PricingComponent plans={corporate} index={3} />
                    </div>
                </div>

                <div className='flex flex-col  justify-center pricing-plans-mob'>
                    <div className="tabs flex items-center justify-center mb-5 px-3">
                        <button onClick={() => handlePlanChange('Starter')} style={{ background: selectedPlan === 'Starter' ? '#1C2639' : '', color: selectedPlan === 'Starter' ? '#fff' : '' }}>Starter</button>
                        <button onClick={() => handlePlanChange('Financial')} style={{ background: selectedPlan === 'Financial' ? '#1C2639' : '', color: selectedPlan === 'Financial' ? '#fff' : '' }}>Financial</button>
                        <button onClick={() => handlePlanChange('Corporate')} style={{ background: selectedPlan === 'Corporate' ? '#1C2639' : '', color: selectedPlan === 'Corporate' ? '#fff' : '' }}>Corporate</button>
                    </div>
                    <div className='flex justify-center px-5'>
                        <div className='flex flex-col items-start justify-center pricing-header bg-[#DDDDDD] w-[50%]'>
                            <div className='border-[1px] border-white p-[30px] pricing-header-inner'>
                                <h1>Features</h1>
                                <p>Choose the package which suits you the best and leave the rest to us!</p>
                            </div>
                            <div className='flex flex-col w-full'>
                                {features.map((data, index) => (
                                    <div key={index} className='border-[1px] border-white flex items-center justify-center h-[62px] text-center pricing-feature'>
                                        <h2>{data.feature}</h2>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            {selectedPlan === 'Starter' && (
                                <PricingComponent plans={starter} index={1} />
                            )}
                            {selectedPlan === 'Financial' && (
                                <PricingComponent plans={financial} index={1} />
                            )}
                            {selectedPlan === 'Corporate' && (
                                <PricingComponent plans={corporate} index={1} />
                            )}
                        </div>
                    </div>
                </div>

                <div className='flex flex-col items-center justify-center w-full mt-20 mb-32'>
                    <div className='getintouch mb-5'>
                        <h2>Get in Touch</h2>
                    </div>
                    <div className='pricing-form-outer w-[30%]'>
                        <div className='header w-full h-full' />
                        <form onSubmit={handleSubmit} className='pricing-form flex flex-col gap-4 p-5'>
                            <input
                                type="text"
                                name="fullName"
                                value={formState.fullName}
                                onChange={handleChange}
                                placeholder='Full Name*'
                                required
                            />

                            <input
                                type="email"
                                name="workEmail"
                                value={formState.workEmail}
                                onChange={handleChange}
                                required
                                placeholder='Work Email*'
                            />

                            <input
                                type="text"
                                name="company"
                                value={formState.company}
                                onChange={handleChange}
                                required
                                placeholder='Company*'
                            />

                            <input
                                type="tel"
                                name="phoneNumber"
                                value={formState.phoneNumber}
                                onChange={handleChange}
                                required
                                placeholder='Phone Number*'
                            />

                            <input
                                type="text"
                                name="country"
                                value={formState.country}
                                onChange={handleChange}
                                required
                                placeholder='Country*'
                            />

                            <textarea
                                name="message"
                                value={formState.message}
                                onChange={handleChange}
                                placeholder='Message'
                            />

                            <button type="submit" className='bg-[#1C2639] text-white py-2'>Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing
