import React from 'react';

const PricingComponent = ({ plans, index }) => {
    const plansIndex = index === 3;
    return (
        <>
            <div className="pricing-table">
                {plans.map((plan, planIndex) => (
                    <div key={planIndex} className="plan">
                        <div className={`plan-header flex flex-col gap-2 items-center justify-center p-[25px] border-white border-[1px] h-[164px] ${plansIndex ? 'bg-[#1C2639]' : 'bg-[#DDDDDD]'}`}>
                            <h3 className={`${plansIndex ? 'text-white' : ''}`}>{plan.title}</h3>
                            <p className={`price ${plansIndex ? 'text-white' : ''}`}>{plan.price}</p>
                            <button className={`access-button ${plansIndex ? 'access-button-special' : ''}`}>
                                <a href={plan.buttonLink} target="_blank" rel="noopener noreferrer">GET ACCESS NOW</a>
                            </button>
                        </div>
                        <div className="plan-features">
                            {plan.features.map((feature, featureIndex) => (
                                <div key={featureIndex} className={`feature flex items-center justify-center p-5 border-white border-[1px] h-[62px] ${plansIndex ? 'bg-[#1C2639]' : 'bg-[#DDDDDD]'}`}>
                                    {featureIndex < 3 ? (
                                        <span className={`text ${plansIndex ? 'text-white' : 'text-black'}`}>{feature.included}</span>
                                    ) : (
                                        <img src={feature.included} className='flex self-center justify-center w-[20px] h-[20px]' alt='' />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default PricingComponent;
