import React from 'react'
import HeroSec from '../../../Components/HeroSec/HeroSec.tsx'
import registered from '../../../Assets/registered.png'
import launch from '../../../Assets/launched.png'
import press from '../../../Assets/press.png'
import users from '../../../Assets/users.png'

import yahoofinance from '../../../Assets/yahoofinance.png'
import yahoonews from '../../../Assets/yahoonews.png'
import wsj from '../../../Assets/WSJ.png'
import citypaper from '../../../Assets/citypaper.webp'
import nytimes from '../../../Assets/NYtimes.png'
import startribune from '../../../Assets/startribune.png'
import pamedia from '../../../Assets/pa.svg'
import nexis from '../../../Assets/nexis.svg'
import newsvoir from '../../../Assets/newsvoir.png'
import mm from '../../../Assets/mm.png'
import medianet from '../../../Assets/medianet.png'
import marketwatch from '../../../Assets/marketwatch.png'
import nasdaq from '../../../Assets/nasdaq.png'
import ibt from '../../../Assets/IBT.png'
import google from '../../../Assets/googlenews.png'
import fox from '../../../Assets/foxnews.png'
import forbes from '../../../Assets/forbes.png'
import dnewswire from '../../../Assets/Dnewswire.png'
import cnbc from '../../../Assets/cnbc.png'
import benzinga from '../../../Assets/benzinga.png'
import bloomberg from '../../../Assets/bloomberg.png'
import ask from '../../../Assets/ask.png'
import applenews from '../../../Assets/applenews.png'
import ap from '../../../Assets/AP.png'
import aol from '../../../Assets/AOL.png'
import americanpress from '../../../Assets/americanpress.png'

import FAQ from '../../../Components/FAQs/FAQ.tsx'


const WhatWeDo = () => {

    const cards = [
        {
            icon: "fi fi-ss-file",
            heading: 'Limitless freedom',
            text: 'Welcome to our straightforward pay-as-you-go pricing: no annual contracts or tiers. Pay only for what you use, with unmatched flexibility and no hidden fees. Adjust your usage seamlessly for maximum value.'
        },
        {
            icon: 'fi fi-sr-stopwatch',
            heading: 'No Word Limits',
            text: 'ThriveNewsWire allows unrestricted expression with unlimited word count for press releases. Craft compelling stories and delve into details without limitations.'
        },
        {
            icon: 'fi fi-br-link-alt',
            heading: 'Link It Up',
            text: 'ThriveNewsWire levels up your press releases! Add multiple hyperlinks and images to boost engagement and clarity. Tell your story, show it visually, and make it pop!'
        },
        {
            icon: 'fi fi-sr-bullseye-arrow',
            heading: 'Geo-Targeting',
            text: 'ThriveNewsWire targets your press release, reaching the perfect audience by location, interests, and industry. No more shouting into the void – your message lands where it counts.'
        },
        {
            icon: 'fi fi-ss-bell',
            heading: 'Live Notifications',
            text: 'ThriveNewsWire offers instant news alerts via email and desktop notifications, ensuring you stay informed on the updates you care about without missing a beat.'
        },
        {
            icon: 'fi fi-br-link-alt',
            heading: '24/7 Customer Care',
            text: 'ThriveNewsWire offers 24/7 customer support for assistance anytime, anywhere. We prioritize urgent press releases for maximum visibility, ensuring instant worldwide reach.'
        },
        {
            icon: 'fi fi-sr-hand-holding-usd',
            heading: 'Ease, Velocity, and Cost Efficiency',
            text: 'ThriveNewsWire offers effortless news distribution with streamlined press release creation and transparent flat-rate pricing. Enjoy simplicity and affordability with no hidden fees.'
        },
        {
            icon: 'fi fi-sr-bullseye-arrow',
            heading: 'Market Segment Targeting',
            text: 'ThriveNewsWire connects you with your ideal audience across industries, from tech to healthcare. Amplify your message with laser-focused precision. Start reaching your audience today!'
        },
        {
            icon: 'fi fi-br-link-alt',
            heading: 'Online Visibility Optimization            ',
            text: "ThriveNewsWire's SEO experts boost your press release to Google's top ranks, targeting keywords strategically to reach new audiences and generate traffic and leads. Invest in SEO and ignite your brand's growth today!"
        },
    ]
    const grid = [
        {
            imgSrc: registered,
            number: '6,285+',
            text: 'Registered Numbers'
        },
        {
            imgSrc: launch,
            number: '2,021',
            text: 'LAUNCHED'
        },
        {
            imgSrc: press,
            number: '37,285',
            text: 'PRESS RELEASES'
        },
        {
            imgSrc: users,
            number: '6,285+',
            text: 'Active Users'
        },
    ]

    const logoslower = [
        {
            image: yahoonews
        },
        {
            image: yahoofinance
        },
        {
            image: wsj
        },
        {
            image: citypaper
        },
        {
            image: nytimes
        },
        {
            image: startribune
        },
        {
            image: pamedia
        },
        {
            image: nexis
        },
        {
            image: newsvoir
        },
        {
            image: mm
        },
        {
            image: medianet
        },
        {
            image: marketwatch
        },
        {
            image: nasdaq
        },
        {
            image: ibt
        },
        {
            image: google
        },
        {
            image: fox
        },
        {
            image: forbes
        },
        {
            image: dnewswire
        },
        {
            image: cnbc
        },
        {
            image: benzinga
        },
        {
            image: bloomberg
        },
        {
            image: ask
        },
        {
            image: applenews
        },
        {
            image: ap
        },
        {
            image: aol
        },
        {
            image: americanpress
        }

    ]
    return (
        <>
            <HeroSec />
            <div className='whatwedo-main flex flex-col items-center justify-center bg-[#F6F6F6] px-52'>
                <div className='card-grid grid grid-cols-3 w-full items-center gap-8 py-20'>
                    {cards.map((data, index) => (
                        <div key={index} className='flex flex-col items-center justify-center bg-white p-5 card h-[320px]'>
                            <i className={data.icon}></i>
                            <h2>{data.heading}</h2>
                            <p>{data.text}</p>
                        </div>
                    ))}
                </div>
                <div className='aboutus-data grid grid-cols-4 pt-12 gap-20'>
                    {grid.map((grid, index) => (
                        <div key={index} className='bg-[#1C2639] flex flex-col items-center justify-center px-16 py-6 rounded-[5px] gap-4' >
                            <img src={grid.imgSrc} alt="" />
                            <h2>{grid.number}</h2>
                            <span>{grid.text}</span>
                        </div>
                    ))}
                </div>
                <div className='flex flex-col items-center justify-center py-24 gap-12'>
                    <div>
                        <h2 className='news'>
                            News & Media
                        </h2>
                    </div>
                    <div className='lower-logos grid grid-cols-7 gap-12'>
                        {logoslower.map((data, index) => (
                            <div key={index} className='lower-logo-container'>
                                <img src={data.image} alt="" className='w-full' />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <FAQ />
        </>
    )
}

export default WhatWeDo
