import React from 'react';
import { useParams } from 'react-router-dom';
import { BlogPost as BlogPostType, blogCard } from './Blogs.tsx';

const BlogPost: React.FC = () => {
    let { blogId } = useParams<{ blogId?: string }>();

    const post = blogCard.find(p => p.id === blogId);

    if (!post) {
        return <div>Post not found.</div>;
    }

    return (
        <>
            <div>
                <div className='pricing-hero bg-[#1C2639] flex flex-col items-center justify-center w-full py-20'>
                    <h1>Single Post</h1>
                </div>

                <div className='flex flex-col items-center justify-center px-32 blogpost-single pt-10  pb-40'>
                    <div>
                        <img src={post.imgSrc} alt="" />
                    </div>
                    <div className='pt-2'>
                        <h2>
                            {post.heading}
                        </h2>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div>
                            <p>
                                {post.paraA}
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingA}
                            </h3>

                            <p>
                                {post.paraB}
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingB}
                            </h3>
                            <p dangerouslySetInnerHTML={{ __html: post.paraC }}>
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingC}

                            </h3>
                            <p dangerouslySetInnerHTML={{ __html: post.paraD }}>
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingD}

                            </h3>
                            <p dangerouslySetInnerHTML={{ __html: post.paraE }}>

                            </p>
                        </div>
                        <div>

                            <h3>
                                {post.headingE}

                            </h3>
                            <p>
                                {post.paraF}

                            </p>
                        </div>
                        <div>

                            <h3>
                                {post.headingF}
                            </h3>
                            <p>
                                {post.paraG}
                            </p>
                        </div>
                        <div>

                            <h3>
                                {post.headingG}
                            </h3>
                            <p dangerouslySetInnerHTML={{ __html: post.paraH }}>

                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingH}

                            </h3>
                            <p dangerouslySetInnerHTML={{ __html: post.paraI }}>

                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingI}
                            </h3>
                            <p>
                                {post.paraJ}

                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingJ}
                            </h3>
                            <p>
                                {post.paraK}
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingK}
                            </h3>
                            <p>
                                {post.paraL}
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingL}
                            </h3>
                            <p>
                                {post.paraM}
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingM}
                            </h3>
                            <p dangerouslySetInnerHTML={{ __html: post.paraN }}>
                            </p>
                        </div>
                        <div>
                            <h3>
                                {post.headingN}
                            </h3>
                            <p>
                                {post.paraO}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogPost;
