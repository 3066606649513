import React, { useState } from 'react'
import './NewsLetter.scss'
const NewsLetter = () => {
    const [value, setValue] = useState('');
    return (
        <>
            <div className='items-center flex justify-center absolute top-[-22%] newsletter-outer'>
                <div className='flex p-16 justify-between items-center bg-[#1C2639] w-[70%] border-white border-[2px] rounded-[20px] newsletter-main'>
                    <div className='flex flex-col w-1/2 newsletter gap-6'>
                        <h1>Join Our Newsletter</h1>
                        <p>Stay up-to-date with exclusive offers, latest updates, and exciting news by subscribing to our newsletter today. Don’t miss out!</p>
                    </div>
                    <div className='flex'>
                        <input value={value} onChange={(e) => setValue(e.target.value)} className='py-[5px] px-3 rounded-[5px]' placeholder='Your Email' />
                        <button className='bg-[#1C2639] border-[1px] border-white px-3 py-1 rounded-[5px] text-white ml-[-8px]'>Send</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewsLetter
