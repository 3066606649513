import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './NewsRoom.scss';
import Loader from '../../Components/Loader.tsx';
import faceBook from '../../Assets/fb-news.svg';
import linkedIn from '../../Assets/linkedin-news.svg';
import twitter from '../../Assets/x.svg';
import NotFound from '../NotFound/NotFound.tsx';

const PressReleaseDetail = () => {
    const { id } = useParams();
    const [pressRelease, setPressRelease] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pressReleases, setPressReleases] = useState([]);

    useEffect(() => {
        const apiURL = `https://portal.thrivenewswire.com/api/v1/get-specific-press?press_id=${id}`;
        const fetchData = async () => {
            try {
                const response = await fetch(apiURL);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setPressRelease(result?.data?.pressRelease);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        const apiURL = 'https://portal.thrivenewswire.com/api/v1/all-press-release';
        const fetchData = async () => {
            try {
                const response = await fetch(apiURL);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setPressReleases(result?.data?.pressReleases);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div className='loader'><Loader /></div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!pressRelease) {
        return <div><NotFound /></div>;
    }

    const handleCardClick = (id) => {
        const newTab = window.open(`/press-release/${id}`, '_blank');
        newTab.focus();
    };

    // Shuffle function to randomize the array
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    // Shuffle the press releases and pick any 4
    const randomPressReleases = shuffleArray([...pressReleases]).slice(0, 4);

    return (
        <>
            <div className='press-release-detail-main'>
                <div className="press-release-detail-left">
                    <h1>{pressRelease?.title}</h1>
                    <p className='press-date'>PRESS DATE • {pressRelease?.published_date}</p>
                    <div className='press-left-icons'>
                        <a href={pressRelease?.facebook_url ?? '#'} target='_BLANK' rel="noreferrer"><img src={faceBook} alt="" /></a>
                        <a href={pressRelease?.linkedin_url ?? '#'} target='_BLANK' rel="noreferrer"><img src={linkedIn} alt="" /></a>
                        <a href={pressRelease?.twitter_url ?? '#'} target='_BLANK' rel="noreferrer"><img src={twitter} alt="" /></a>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: pressRelease?.body }}></p>
                    <div className='keywords-outer'>
                        <p>TAGS</p>
                        <div className='press-keywords'>
                            {pressRelease?.keywords && pressRelease?.keywords.split(',').map((keyword, index) => (
                                <span key={index} className='keyword-tag'>{keyword.trim()}</span>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="press-release-detail-right">
                    <img src={`https://portal.thrivenewswire.com/storage/${pressRelease?.logo}`} alt={pressRelease?.title} className='detail-logo-press' />
                    <div className='newsletter-grid-detail'>
                        <h1>More Press Releases</h1>
                        {randomPressReleases.map((item) => (
                            <div className="cardPress-detail" key={item?.id} >
                                <div className="card-body-detail">
                                    <h3 className="press-release-title-detail">{item?.title}</h3>
                                    <p className='read-press' onClick={() => handleCardClick(item?.id)}>Read Press Release &gt;&gt;</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PressReleaseDetail;
