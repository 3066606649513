import React from 'react'
import '../About.scss'
import aboutUpper from '../../../Assets/about-1.jpg'
import aboutLower from '../../../Assets/about-2.png'
import downarrow from '../../../Assets/downArrow.svg'
const WhoWeAre = () => {

    const aboutContext = [
        {
            heading: 'About Us',
            text: "ThriveNewsWire isn't your average press release distribution company. Established in 2021 by a powerhouse team of industry veterans, we understand the ever-evolving communication landscape. Our secret sauce? Blending the best of traditional press release distribution with cutting-edge tech trends. The result? Communication services that stand head and shoulders above the rest. So, if you're looking to get your message heard loud and clear, look no further than ThriveNewsWire. We're the one-stop shop for all your communication needs."
        },
        {
            heading: 'Mission Statement',
            text: "Forget press releases that get lost in the digital void. ThriveNewsWire takes a different approach. We obsess over optimizing press release distribution with the latest tech, like a tireless athlete constantly pushing their limits. This dedication translates to real results for our clients: maximized return on investment. So, if you're looking to make your press releases truly impactful, choose the team that goes above and beyond – choose ThriveNewsWire. We'll ensure your message not only gets seen but resonates powerfully."
        },
        {
            heading: 'Vision Statement',
            text: "Forget the ordinary. ThriveNewsWire is using AI to build the future of news: smarter communication, wider reach, personalized experiences. Join us and become a leader in the News-Tech revolution!"
        }
    ]

    return (
        <>
            <div>
                <div className='whoWeAre-hero bg-[#1C2639] flex flex-col items-center justify-center w-full py-20'>
                    <h1>Who We Are</h1>
                </div>
                <div className='flex items-center justify-center px-52 py-20 whoweare-first'>
                    <div className='flex flex-col  gap-20 w-[40%] aboutupper-context-main'>
                        {aboutContext.map((data, index) => (
                            <div key={index} className='aboutUpper-context flex flex-col items-start'>
                                <h2>
                                    {data.heading}
                                </h2>
                                <p>
                                    {data.text}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-col gap-10'>
                        <img src={aboutUpper} alt="" />
                        <img src={aboutLower} alt="" />
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center mb-40'>
                    <div className='whyus pb-5'>
                        <h2>Why Us?</h2>
                    </div>

                    <div className='flex items-start justify-center gap-10 whowearecards-web'>
                        <div className='flex flex-col w-[25%] gap-80'>
                            <div className='whoarewe-cards'>
                                <h1>
                                    Our Experts
                                </h1>
                                <p>
                                    Forget cookie-cutter solutions. ThriveNewsWire boasts a team of passionate experts, each a master in their field. But they don't stop there. Their collaborative culture thrives on fresh ideas, leading to unique and impactful distribution strategies for your message. So, if you're seeking expertise with an innovative edge, look no further than ThriveNewsWire.
                                </p>
                            </div>
                            <div className='whoarewe-cards'>
                                <h1>
                                    Enhanced Online Presence
                                </h1>
                                <p>
                                    Tired of hidden corners and missed audiences? ThriveNewsWire blasts your press releases across hundreds of channels, dominating the digital world. Social media explodes, Google buzzes, and your brand presence erupts. Target EVERY potential client, pay-as-you-go, with expert strategies and zero long-term commitment. Go viral the smart way. Contact ThriveNewsWire today.
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center'>
                            <div className='bg-[#DDDDDD] p-10 rounded-full'><img src={downarrow} alt="" className='w-[20px] h-[20px]' /></div>
                            <div className='w-[1px] h-[200px] bg-[#DDDDDD] line-a' />

                            <div className='bg-[#DDDDDD] p-10 rounded-full'><img src={downarrow} alt="" className='w-[20px] h-[20px]' /></div>
                            <div className='w-[1px] h-[200px] bg-[#DDDDDD] line-b' />

                            <div className='bg-[#DDDDDD] p-10 rounded-full'><img src={downarrow} alt="" className='w-[20px] h-[20px]' /></div>
                            <div className='w-[1px] h-[200px] bg-[#DDDDDD] line-c' />

                            <div className='bg-[#DDDDDD] p-10 rounded-full'><img src={downarrow} alt="" className='w-[20px] h-[20px]' /></div>
                        </div>
                        <div className='flex flex-col w-[25%] mt-80 gap-80'>
                            <div className='whoarewe-cards-right'>
                                <h1>
                                    No Contracts
                                </h1>
                                <p>
                                    Forget restrictive contracts and hidden fees. ThriveNewsWire believes in giving you the freedom to succeed. That's why they offer pay-as-you-go services, with no memberships or long-term commitments. Get the expertise you need, maximize your benefits, and enjoy the flexibility you deserve. Choose ThriveNewsWire and experience communication freedom.
                                </p>
                            </div>
                            <div className='whoarewe-cards-right'>
                                <h1>
                                    Bulk Packages
                                </h1>
                                <p>
                                    Blast your message across the web with bulk press releases at industry-crushing prices. ThriveNewsWire doesn't just offer quantity, they deliver quality with targeted distribution, social media amplification, and flexible, cost-effective packages. Get heard loud and clear, without breaking the bank. Contact ThriveNewsWire today.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='whowearecards-mob flex items-start '>
                        <div className='flex flex-col items-start gap-[4rem]'>
                            <div className='whoarewe-cards-right'>
                                <h1>
                                    Our Experts
                                </h1>
                                <p>
                                    Forget cookie-cutter solutions. ThriveNewsWire boasts a team of passionate experts, each a master in their field. But we don't stop there. Our collaborative culture thrives on fresh ideas, leading to unique and impactful distribution strategies for your message. So, if you're seeking expertise with an innovative edge, look no further than ThriveNewsWire.
                                </p>
                            </div>
                            <div className='whoarewe-cards-right'>
                                <h1>
                                    No Contracts
                                </h1>
                                <p>
                                    Forget restrictive contracts and hidden fees. ThriveNewsWire believes in giving you the freedom to succeed. That's why we offer pay-as-you-go services, with no memberships or long-term commitments. Get the expertise you need, maximize your benefits, and enjoy the flexibility you deserve. Choose ThriveNewsWire and experience communication freedom.
                                </p>
                            </div>
                            <div className='whoarewe-cards-right'>
                                <h1>
                                    Enhanced Online Presence
                                </h1>
                                <p>
                                    Tired of hidden corners and missed audiences? ThriveNewsWire blasts your press releases across hundreds of channels, dominating the digital world. Social media explodes, Google buzzes, and your brand presence erupts. Target EVERY potential client, pay-as-you-go, with expert strategies and zero long-term commitment. Go viral the smart way. Contact ThriveNewsWire today.
                                </p>
                            </div>
                            <div className='whoarewe-cards-right'>
                                <h1>
                                    Bulk Packages
                                </h1>
                                <p>
                                    Blast your message across the web with bulk press releases at industry-crushing prices. ThriveNewsWire doesn't just offer quantity; they deliver quality with targeted distribution, social media amplification, and flexible, cost-effective packages. Get heard loud and clear without breaking the bank. Contact ThriveNewsWire today.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhoWeAre
