import React from 'react'
import './NotFound.scss'
const NotFound = () => {
    return (
        <>
            <div className="notFound">
                <div className="notFound-404">
                    <h3>Oops! Page not found</h3>
                    <h1><span>4</span><span>0</span><span>4</span></h1>
                </div>
                <h2>We are sorry, but the page you requested was not found.</h2>
                <button className='px-5 py-2 bg-[#1C2639] rounded-[5px] text-white mt-3'><a href="/">Home</a></button>

            </div>
        </>
    )
}

export default NotFound
