import React from 'react'
import logo from '../../Assets/Logo.png'
import './Footer.scss'

import fb from '../../Assets/fb.svg'
import lin from '../../Assets/linkedin.svg'
import ig from '../../Assets/ig.svg'
import idrakai from '../../Assets/idrakailogo.png'
import NewsLetter from '../NewsLetter/NewsLetter.tsx'
const Footer = () => {
    return (
        <>

            <div className='footer relative items-center  bg-[#1C2639] px-52 flex flex-col gap-5'>
                <NewsLetter />
                <div className='flex items-start justify-center  pt-52 footer-main'>
                    <div className='flex flex-col items-start justify-center text gap-5 w-[40%] footer-main-upper'>
                        <img src={logo} alt="" className='w-[50%]' />
                        <p className='w-[60%]'>Welcome to ThriveNewsWire, where your news thrives. We're dedicated to transforming your stories into powerful narratives that captivate and inspire audiences everywhere.</p>
                        <div className='flex gap-3'>
                            <a href="https://www.facebook.com/thrivepressrelease" target="_blank" rel="noopener noreferrer"><img src={fb} alt="" /></a>
                            <a href="https://www.linkedin.com/company/thrivenewswire/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><img src={lin} alt="" /></a>
                            <a href="https://www.instagram.com/thrivepressrelease?igsh=MWh4em1rNGZubTZwdg==" target="_blank" rel="noopener noreferrer"><img src={ig} alt="" /></a>
                        </div>
                        <div className='footer-text'>
                            Quick Links
                        </div>
                        <div className='idrakai-footer'>
                            <img src={idrakai} alt="" />
                        </div>
                    </div>
                    <div className='grid grid-cols-3 gap-10 footer-lower-grid'>
                        <div className='w-fit flex  flex-col gap-8'>
                            <h2>
                                Quick Links
                            </h2>
                            <div className='flex flex-col gap-3'>
                                <a href="/WhoWeAre"><p>About us</p></a>
                                <a href="/Blogs"><p>Blogs</p></a>
                                <a href="/Pricing"><p>Pricing</p></a>
                                <a href="/ContactUs"><p>Contact</p></a>
                            </div>
                        </div>
                        <div className='w-fit flex  flex-col gap-8'>
                            <h2>
                                Useful Links
                            </h2>
                            <div className='flex flex-col gap-3'>
                                <a href="/PrivacyPolicy" target="_blank" rel="noopener noreferrer"><p>Privacy Policy</p></a>
                                <a href="/Terms&Condition" target="_blank" rel="noopener noreferrer"><p>Terms and Conditions</p></a>
                                <a href="/Editorial" target="_blank" rel="noopener noreferrer"><p>Editorial Guidelines</p></a>
                                <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer"><p>Google Privacies Policy</p></a>
                            </div>
                        </div>
                        <div className='w-fit flex  flex-col gap-8'>
                            <h2>
                                Work Hours
                            </h2>
                            <div className='flex flex-col gap-3'>
                                <a href=""><p>9 AM - 5 PM , Monday - Saturday</p></a>
                                <a href="" target="_blank" rel="noopener noreferrer"><p>34 N Franklin Ave 687 2138, Pinedale, WY 82941, USA</p></a>
                                <a href="" target="_blank" rel="noopener noreferrer"><p>media@thrivenewswire.com</p></a>
                                <a href="" target="_blank" rel="noopener noreferrer"><p>+1 (888) 564-1510</p></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyright w-full flex flex-col items-center justify-center gap-5'>
                    <div className='h-[1px] w-full bg-gray-600' />
                    <h3>Copyright © ThriveNewsWire 2024. All rights reserved.</h3>
                </div>
            </div>

        </>
    )
}

export default Footer
