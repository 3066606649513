import React from 'react'
import './Privacy.scss'
const Privacy = () => {
    return (
        <>
            <div className='flex flex-col items-start justify-center px-52 privacy-template gap-3 pt-5 pb-40'>
                <div>
                    <h1>
                        Privacy Policy
                    </h1>
                </div>
                <div>
                    <p>
                        Our goal is to ensure your comfort while using the Site. To achieve this, we have crafted this Privacy Policy to apprise you of the information we may collect from you on the Site and how we might utilize that information. As outlined in this Privacy Policy, we may gather various types of information and employ them in different manners.
                    </p>
                </div>
                <div>
                    <p>
                        Additionally, this policy delineates your options regarding the utilization, access, and rectification of your personal information. We encourage you to carefully review the terms outlined in this Privacy Policy. By accessing and using the Site, you consent to abide by the terms presented herein. Should you disagree with any part of this Privacy Policy, we kindly ask that you refrain from using the Site. Please note that this Privacy Policy pertains solely to information gathered on the Site and does not extend to information collected through other means apart from the Site.
                    </p>
                </div>
                <div>
                    <h2>
                        How We Collect Information from You and Why?
                    </h2>
                </div>
                <div>
                    <h3>
                        Information You Provide to Us:
                    </h3>
                    <p>
                        We gather information directly from you, which includes but is not limited to the following instances:
                    </p>
                    <p>
                        •  When you register for or order a Service. <br />
                        •  When a company, organization, or individual authorizes you to manage its account or utilize our Services on its behalf.<br />
                        •  When you subscribe to a newsletter.<br />
                        • When you offer feedback about our Services.<br />
                        • When you reach out to us with questions or comments regarding a Service.<br />
                    </p>
                </div>
                <div>
                    <h3>
                        Information We Collect Through Your Use of our Services
                    </h3>
                    <p>
                        We gather specific technical details through cookies and other tracking methods to assist us in operating and delivering our Services to you. This information encompasses, but is not limited to:
                    </p>
                    <p>
                        • Details concerning the systems and device(s) you utilize to access our Services, which may include MAC address, IP address, browser type and version, time zone settings, browser plug-in types and versions, operating system and platform, device type, and device identifiers.<br />
                        • Data regarding your visits to the Websites, including the complete URL clickstream to, through, and from the Websites (including date and time).<br />
                        • Details about your usage of our mobile apps, including the content you access in our mobile apps and the corresponding date and time.<br />
                        • Information aimed at facilitating your use of our Services (including granting access to third-party websites and services), such as URL requests, destination IP addresses, or device configuration specifics.<br />
                        • Pages you browse or search for, page response times, download errors, duration of visits to specific pages, page interaction details (such as scrolling, clicks, and mouse-overs), your interaction with particular variable/dynamic elements of a page, and the techniques utilized to navigate away from the page.
                    </p>
                    <p>If you opt out of allowing us to collect such information (for instance, by disabling cookies), we may be unable to provide some or all of our Services to you.</p>
                </div>
                <div>
                    <h3>
                        Information We Collect about You from Third Parties:
                    </h3>
                    <p>
                        Our Services may occasionally feature links to and from social media platforms. Should you opt to connect with us via a social media platform, we may, to the extent available, gather additional information from you. This may include your usernames, profile picture, contact details, contact list, and the profile pictures of your contacts, retrieved through the social media platform. It’s important to note that social media platforms may also collect information from you. We lack control over the collection, utilization, and sharing practices of social media platforms and encourage you to review their policies and practices concerning usage and disclosure, including their data security protocols, before engaging with them. <br />
                        <br />
                        We reserve the right to amalgamate the information we acquire about you from the various sources described above.
                    </p>
                </div>
                <div>
                    <h3>
                        How Your Information May Be Used:
                    </h3>
                    <p>
                        We may utilize any information we gather, including personal and technical data, in accordance with the descriptions provided to you at the time of collection, for the following objectives:

                        • Providing, maintaining, and enhancing our Services: This includes operational tasks such as administration, troubleshooting, managing account access, and security protection. It encompasses evaluating our Services, devising new features, products, or services, and offering customer service and support.<br />
                        • Tailoring content: We personalize the information and content presented to you, encompassing marketing, promotional, sponsored content, and advertising within the Services.<br />
                        • Marketing and communications: We may directly communicate with you regarding your usage of a registered account or Service. This involves furnishing information about products or services, whether from us or third parties, that might pique your interest. We may also serve advertisements and respond to your inquiries, feedback, or queries.<br />
                        • Research and analytics: Our efforts include researching and analyzing your interactions with our Services, monitoring usage and activity trends, and generating statistical reports. These reports may be provided to customers, containing aggregate trends and information about organizations utilizing our Services. Additionally, we gauge the effectiveness of content, communications, website visits, web traffic, and advertising, delivering pertinent advertising to you and similar customers.<br />
                        • Legal compliance: We adhere to laws, regulations, and requests from law enforcement or governmental entities. This involves fulfilling our contractual obligations, enforcing our rights, and utilizing information as evidence in legal proceedings.<br />
                        • Protecting rights and interests: We safeguard the safety, rights, or property of ThriveNewsWire or any third party. This includes investigating, preventing, or addressing activities deemed illegal, unethical, or posing a potential risk, according to ThriveNewsWire’s sole discretion.<br />
                        Moreover, we may generate aggregate or anonymous information concerning your and other users’ utilization of our Services for purposes akin to those outlined above.
                    </p>
                </div>
                <div>
                    <h3>
                        How Your Information May Be Shared:
                    </h3>

                    <p>
                        We may disclose any information, including personal data, under the following circumstances: <br />
                        With our subsidiaries and affiliates, including any future subsidiaries or affiliates.
                    </p>
                    <p>
                        • With our service providers and subcontractors to the extent reasonably necessary to enable us to provide our Services. For instance, we may utilize a third-party platform for email services and cannot communicate with you without disclosing your email address to our third-party email provider.<br />
                        • With a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of any of our assets. This includes scenarios where our assets are transferred during bankruptcy, liquidation, or similar proceedings, including during the planning or negotiations of such transactions.<br />
                        • With a third party with whom we are legally obligated to share information about you.<br />
                        • With the company or organization you represent, upon their request.<br />
                        • With you or other third parties with your explicit consent.<br />
                        • To comply with any court order, valid subpoena, law, or legal process. This includes responding to government or regulatory requests.<br />
                        • To investigate potential unauthorized access or misuse of our Services, or to enforce or apply our Terms of Service and other agreements.<br />
                        • To safeguard our assets or rights, including for billing and collection purposes.<br />
                        • If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of ThriveNewsWire, our customers, or others. This may involve exchanging information with other companies and organizations for fraud protection and credit risk reduction.<br />
                        • With any other entity disclosed by us when you provide the information.<br />
                        We may also share aggregate or anonymous data for marketing, advertising, research, compliance, or other purposes.
                    </p>
                    <p>In the event that we receive a request from a governmental entity to provide your personal information, we will make reasonable efforts to notify you of such request, to the extent reasonably possible and legally permissible.</p>
                </div>
                <div>
                    <h3>
                        Cookies & Other Tracking Mechanisms:
                    </h3>
                    <p>
                        We utilize cookies and other tracking mechanisms to enhance your experience on our platform. These technologies help us understand how you interact with our website and improve our services. By using our website, you consent to the use of cookies and similar technologies as described in our Cookie Policy
                    </p>
                </div>
                <div>
                    <h3>
                        Keeping Your Personal Information Secure:
                    </h3>
                    <p>
                        The security of your information is paramount to us at ThriveNewsWire. We have implemented a range of administrative, technical, and physical safeguards to protect your personal data against theft or misuse. While we strive to maintain the security of your information, it’s important to note that no system can guarantee complete security. Therefore, your use of our services and communication with us is at your own risk. Please ensure to protect your usernames and passwords and exercise caution when transmitting information over the internet.
                    </p>
                </div>
                <div>
                    <h3>
                        Children:
                    </h3>
                    <p>
                        Our services are tailored for working professionals and are not intended for individuals under 16 years of age or the relevant age of majority. We do not knowingly collect personal information from children under this age. If you are under 13 years of age or the age of majority, you are not permitted to submit any personal information to us
                    </p>
                </div>
                <div>
                    <h3>
                        Third-Party Information Collection:
                    </h3>
                    <p>
                        Our services may include links to third-party websites and services, such as social media platforms, partner networks, and advertisers. Please be aware that these websites and services operate under their own privacy policies, and we do not assume responsibility for their practices. If you choose to access these linked websites or services, we recommend reviewing their privacy policies before submitting any personal information. <br />
                        <br />
                        Third parties unaffiliated with us may also collect information about you, including tracking your browsing history, when you use our services. We do not have control over these third-party collection practices. We advise adjusting your browser settings or installing plug-ins and add-ins if you wish to minimize these third-party collections.
                    </p>
                </div>
                <div>
                    <h3>
                        How Long Will We Keep Your Information For?
                    </h3>
                    <p>
                        We will retain your personal data for the duration of our service provision to you and will not retain it longer than strictly necessary.
                    </p>
                </div>
                <div>
                    <h3>
                        Personal Information Transferred from EEA and/or Switzerland:
                    </h3>
                    <p>
                        We may share your personal data with entities within the ThriveNewsWire group. As an international organization headquartered in the USA, this may involve transferring your personal data outside of the European Economic Area (“EEA”). Some third parties to whom we transfer your personal data are also based outside of the EEA, so their processing of your personal data will similarly involve a transfer outside the EEA. When your personal data is transferred outside of the EEA, we ensure that appropriate mechanisms are in place to safeguard your personal information. This includes using specific contracts approved by the European Commission to afford your personal data the same protection as it has in Europe. <br />
                        <br />
                        Such transfers may occur by or to us in our capacity as a data processor or as a data controller:<br />
                        <br />
                        • Processor: ThriveNewsWire provides software, services, and tools to assist customers in managing all aspects of their marketing and PR campaigns. As a processor, ThriveNewsWire receives information transferred from the EU to the US solely as a processor on behalf of its customers. However, such information may not be required by ThriveNewsWire.<br />
                        • Data Controller: ThriveNewsWire also offers information products designed to help customers research and plan their campaigns more effectively. In this capacity, ThriveNewsWire acts as a data controller of the personal information contained in these products and processes this personal information as a data controller.
                    </p>
                </div>
                <div>
                    <h3>
                        Legal Basis for Processing:
                    </h3>
                    <p>
                        We only use your personal data when permitted by law. This includes circumstances such as: <br />
                        <br />
                        • Where processing your information is necessary to perform the contract we are about to enter into or have entered into with you.<br />
                        • Where processing your information is necessary to comply with a legal or regulatory obligation.<br />
                        • Where it is in our legitimate interests to do so, provided such interests do not override your rights and interests.<br />
                        • Where we have obtained your consent.
                    </p>
                </div>
                <div>
                    <h3>
                        Changes to Our Privacy Policy:
                    </h3>
                    <p>
                        We may update this Privacy Policy to reflect changes in our business practices or legal requirements. Any updates will be posted on this page, and the effective date will be revised accordingly. We encourage you to check periodically for updates. Your continued use of the Services or communication with us about the Services after the updated Privacy Policy is posted constitutes your acceptance of the changes.<br />
                        Please note that we may require your consent to changes in this Privacy Policy for you to continue accessing our Services.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Privacy
