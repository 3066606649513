import React, { useState } from 'react'
import './Contact.scss'
const Contact = () => {
    interface FormState {
        firstName: string;
        lastName: string;
        workEmail: string;
        company: string;
        phoneNumber: string;
        message: string;
    }

    const [formState, setFormState] = useState<FormState>({
        firstName: '',
        lastName: '',
        workEmail: '',
        company: '',
        phoneNumber: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Submit form logic here
        console.log(formState);
    };

    const contactCards = [
        {
            icon: 'fi fi-sr-marker',
            text: '34 N Franklin Ave 687 2138, Pinedale, WY 82941, USA'
        },

        {
            icon: 'fi fi-ss-envelope-open',
            text: 'media@thrivenewswire.com'
        },
        {
            icon: 'fi fi-ss-phone-call',
            text: '+1 (888) 564-1510'
        },
    ]
    return (
        <>
            <div className='pricing-hero bg-[#1C2639] flex flex-col items-center justify-center w-full py-20'>
                <h1>Contact Us</h1>
                <p><a href="/">Home</a>/Contact Us</p>
            </div>
            <div className='flex flex-col gap-10 bg-[#F6F6F6] contact-main'>
                <div className='w-full flex flex-col items-center justify-center mt-5'>
                    <div className='w-[30%] items-start flex flex-col bg-[#1C2639] p-8 gap-4 contact-form-main'>
                        <div>
                            <h2 className='getintouch'>
                                Get in Touch
                            </h2>
                        </div>
                        <form onSubmit={handleSubmit} className='contact-form flex flex-col gap-4 w-full'>
                            <div className='flex justify-between input-divs'>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formState.firstName}
                                    onChange={handleChange}
                                    placeholder='First Name'
                                    required
                                />

                                <input
                                    type="email"
                                    name="lastName"
                                    value={formState.lastName}
                                    onChange={handleChange}
                                    required
                                    placeholder='Last Name'
                                />
                            </div>
                            <div className='flex justify-between input-divs'>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    value={formState.phoneNumber}
                                    onChange={handleChange}
                                    required
                                    placeholder='Phone Number'
                                />

                                <input
                                    type="tel"
                                    name="workEmail"
                                    value={formState.workEmail}
                                    onChange={handleChange}
                                    required
                                    placeholder='Email'
                                />
                            </div>
                            <input
                                type="text"
                                name="company"
                                value={formState.company}
                                onChange={handleChange}
                                required
                                placeholder='Company'
                            />

                            <textarea
                                name="message"
                                value={formState.message}
                                onChange={handleChange}
                                placeholder='Message'
                            />

                            <button type="submit" className='bg-white text-[#1C2639] py-2'>Send</button>
                        </form>
                    </div>
                </div>

                <div className='flex items-start justify-center gap-10 mb-40 contact-cards'>
                    {contactCards.map((data, index) => (
                        <div key={index} className='flex flex-col items-center justify-center bg-white p-4 contact-cards gap-4 w-72'>
                            <i className={data.icon}></i>
                            <p>{data.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Contact
