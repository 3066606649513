import React from 'react'
import HeroSec from '../../Components/HeroSec/HeroSec.tsx'
import HomeAbout from '../../Components/HomeAbout/HomeAbout.tsx'
import FAQ from '../../Components/FAQs/FAQ.tsx'

const Home = () => {
    return (
        <>
            <HeroSec />
            <HomeAbout />
            <FAQ />
        </>
    )
}

export default Home
