import React from 'react'
import './HomeAbout.scss'
import yahoofinance from '../../Assets/yahoofinance.png'
import bloomberg from '../../Assets/bloomberg.png'
import ibt from '../../Assets/IBT.png'
import wsj from '../../Assets/WSJ.png'
import marketwatch from '../../Assets/marketwatch.png'
import ap from '../../Assets/AP.png'

import yahoonews from '../../Assets/yahoonews.png'
import citypaper from '../../Assets/citypaper.webp'
import nytimes from '../../Assets/NYtimes.png'
import startribune from '../../Assets/startribune.png'
import pamedia from '../../Assets/pa.svg'
import nexis from '../../Assets/nexis.svg'
import newsvoir from '../../Assets/newsvoir.png'
import mm from '../../Assets/mm.png'
import medianet from '../../Assets/medianet.png'
import nasdaq from '../../Assets/nasdaq.png'
import google from '../../Assets/googlenews.png'
import fox from '../../Assets/foxnews.png'
import forbes from '../../Assets/forbes.png'
import dnewswire from '../../Assets/Dnewswire.png'
import cnbc from '../../Assets/cnbc.png'
import benzinga from '../../Assets/benzinga.png'
import ask from '../../Assets/ask.png'
import applenews from '../../Assets/applenews.png'
import aol from '../../Assets/AOL.png'
import americanpress from '../../Assets/americanpress.png'

import aboutus from '../../Assets/home-page-about-sec.jpg'
import trophy from '../../Assets/trophy.png'

import registered from '../../Assets/registered.png'
import launch from '../../Assets/launched.png'
import press from '../../Assets/press.png'
import users from '../../Assets/users.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick/lib/slider'


const HomeAbout = () => {

    const logos = [
        {
            imgSrc: yahoofinance
        },
        {
            imgSrc: bloomberg
        },
        {
            imgSrc: ibt
        },
        {
            imgSrc: wsj
        },
        {
            imgSrc: marketwatch
        },
        {
            imgSrc: ap
        },
        {
            imgSrc: yahoofinance
        },
        {
            imgSrc: bloomberg
        },


    ]

    const grid = [
        {
            imgSrc: registered,
            number: '6,285+',
            text: 'Registered Numbers'
        },
        {
            imgSrc: launch,
            number: '2,021',
            text: 'LAUNCHED'
        },
        {
            imgSrc: press,
            number: '37,285',
            text: 'PRESS RELEASES'
        },
        {
            imgSrc: users,
            number: '6,285+',
            text: 'Active Users'
        },
    ]

    const logoslower = [
        {
            image: yahoonews
        },
        {
            image: yahoofinance
        },
        {
            image: wsj
        },
        {
            image: citypaper
        },
        {
            image: nytimes
        },
        {
            image: startribune
        },
        {
            image: pamedia
        },
        {
            image: nexis
        },
        {
            image: newsvoir
        },
        {
            image: mm
        },
        {
            image: medianet
        },
        {
            image: marketwatch
        },
        {
            image: nasdaq
        },
        {
            image: ibt
        },
        {
            image: google
        },
        {
            image: fox
        },
        {
            image: forbes
        },
        {
            image: dnewswire
        },
        {
            image: cnbc
        },
        {
            image: benzinga
        },
        {
            image: bloomberg
        },
        {
            image: ask
        },
        {
            image: applenews
        },
        {
            image: ap
        },
        {
            image: aol
        },
        {
            image: americanpress
        }

    ]

    const settings = {
        dots: true,
        lazyload: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    return (
        <>
            <div className='upper-logos-web flex gap-10 items-center justify-center pt-12'>
                {logos.map((data, index) => (
                    <div className='logos' key={index}>
                        <img src={data.imgSrc} alt="logos" className='w-[70%]' />
                    </div>
                ))}
            </div>

            <div className='upper-logos-mob flex gap-10 items-center justify-center pt-12'>
                <Slider>
                    {logos.map((data, index) => (
                        <div className='logos' key={index}>
                            <img src={data.imgSrc} alt="logos" className='w-[70%]' />
                        </div>
                    ))}
                </Slider>
            </div >

            <div className='flex flex-col items-center justify-center px-52 pt-24 home-about-main'>
                <div className='flex items-center justify-center gap-10 home-about'>
                    <div className='relative w-[40%] home-about-inner'>
                        <img src={aboutus} alt="" className='aboutus-img' />
                        <div className='flex absolute bg-[#1C2639] items-center text-white py-8 image-banner'>
                            <div className='w-[30%] flex self-end justify-center image-banner-inner'>
                                <img src={trophy} alt="" className='w-[60%] trophy' />
                            </div>
                            <div className='image-banner-text'>
                                <h2>We are</h2>
                                <p>Conventional Press release distribution firms</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col aboutus-context items-start w-1/2 gap-12'>
                        <h2 className='flex items-center justify-center gap-3'><i className="fi fi-rs-arrow-circle-right"></i>About Us</h2>
                        <h1>The most effective approach to resolving public relations issues.</h1>
                        <p>ThriveNewsWire sets itself apart from conventional press release distribution firms. Founded in 2021 by a team of seasoned industry professionals, the company boasts a profound grasp of the dynamic communication terrain. Their unique approach blends traditional press release distribution practices with state-of-the-art technological advancements, offering clients an unparalleled service.</p>
                        <button className='px-5 py-6 bg-[#1C2639] rounded-[5px] text-white'><a href="/WhoWeAre" target="_blank">Read More</a></button>
                    </div>
                </div>
                <div className='aboutus-data grid grid-cols-4 pt-12 gap-24'>
                    {grid.map((grid, index) => (
                        <div key={index} className='bg-[#1C2639] flex flex-col items-center justify-center px-14 py-6 rounded-[5px] gap-4' >
                            <img src={grid.imgSrc} alt="" />
                            <h2>{grid.number}</h2>
                            <span>{grid.text}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='news-media flex flex-col items-center justify-center py-24 px-52 gap-12'>
                <div>
                    <h2 className='news'>
                        News & Media
                    </h2>
                </div>
                <div className='lower-logos grid grid-cols-6 gap-12'>
                    {logoslower.map((data, index) => (
                        <div key={index} className='lower-logo-container'>
                            <img src={data.image} alt="" />
                        </div>
                    ))}
                </div>
            </div>

        </>
    )
}

export default HomeAbout
