import React from 'react'
import './NewsRoom.scss'
import NewsroomPress from './NewsRoomPress.tsx'
const NewsRoom = () => {


    return (
        <>
            <div className='newsroom-hero bg-[#1C2639] flex flex-col items-center justify-center w-full py-20'>
                <h1>Newsroom</h1>
                <p>Home/ Newsroom</p>
            </div>
            <div className='mt-4 mb-40'>
                {/* <div className="elfsight-app-264723e5-dfd6-4c22-b392-61ad9e6bfb8d" data-elfsight-app-lazy></div> */}
                <NewsroomPress />
            </div>
        </>
    )
}

export default NewsRoom
