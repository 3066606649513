import React from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Navbar from './Components/Navbar/Navbar.tsx'
import Home from './Pages/Home/Home.tsx';
import './App.scss'
import Footer from './Components/Footer/Footer.tsx';
import { Route, Routes } from 'react-router-dom';
import Pricing from './Pages/Pricing/Pricing.tsx';
import WhoWeAre from './Pages/AboutUs/WhoWeAre/WhoWeAre.tsx';
import WhatWeDo from './Pages/AboutUs/WhatWeDo/WhatWeDo.tsx';
import NewsRoom from './Pages/NewsRoom/NewsRoom.tsx';
import Contact from './Pages/ContactUs/Contact.tsx';
import Blogs from './Pages/Blogs/Blogs.tsx';
import BlogPost from './Pages/Blogs/BlogPost.tsx';
import Privacy from './Pages/Privacy/Terms/Privacy.tsx';
import Terms from './Pages/Privacy/Terms/Terms.tsx';
import Editorial from './Pages/Privacy/Terms/Editorial.tsx';
import PressReleaseDetail from './Pages/NewsRoom/PressReleaseDetail.tsx';
import NotFound from './Pages/NotFound/NotFound.tsx';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Pricing' element={<Pricing />} />
        <Route path='/WhoWeAre' element={<WhoWeAre />} />
        <Route path='/WhatWeDo' element={<WhatWeDo />} />
        <Route path='/Newsroom' element={<NewsRoom />} />
        <Route path="/press-release/:id" element={<PressReleaseDetail />} />
        <Route path='/Blogs' element={<Blogs />} />
        <Route path="/blog/:blogId" element={<BlogPost />} />
        <Route path='/ContactUs' element={<Contact />} />
        <Route path='/PrivacyPolicy' element={<Privacy />} />
        <Route path='/Terms&Condition' element={<Terms />} />
        <Route path='/Editorial' element={<Editorial />} />
        <Route path='/404' element={<NotFound />} />

        {/* Catch-all route for unmatched URLs */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
