import React from 'react'

const Terms = () => {
    return (
        <>
            <div className='flex flex-col items-start justify-center px-52 privacy-template gap-3 pt-5 pb-40'>
                <div>
                    <h1>
                        ThriveNewsWire Terms and Conditions
                    </h1>
                </div>
                <div>
                    <p>
                        This Terms and Conditions Agreement (“Agreement”) encompasses the comprehensive terms and conditions governing the utilization of the ThriveNewsWire.com web services (“Services”) by individuals or entities.


                    </p>
                </div>
                <div>
                    <h3>
                        Responsibilities:

                    </h3>
                    <p>
                        You are accountable for taking reasonable precautions to ensure that materials posted on your site do not violate or infringe upon the rights of any third party, including copyrights, trademarks, privacy, or other personal or proprietary rights
                    </p>
                </div>
                <div>
                    <h3>
                        Definitions:
                    </h3>
                    <p>
                        “We” denotes ThriveNewsWire.com. <br />
                        “You” refers to the applicant. <br />
                        “Site” denotes a World Wide Website. <br />
                        “Links” encompasses the links, headlines, and source names provided by ThriveNewsWire.com as part of the Services. <br />
                        “Publishers” refers to the creators of the articles referenced.
                    </p>
                </div>
                <div>
                    <h3>
                        Refund Policy:
                    </h3>
                    <p>
                        We reserve the right to decline all refund requests except when failing to publish your press release/articles. Refunds are eligible if we reject your press release content and cannot proceed with publication. Refund requests for unused and expired units will be rejected.<br />
                        <br />
                        User Responsibilities and Disclaimer of Warranties:<br />
                        <br />
                        You are liable for the content and accuracy of all news copy and other information submitted by you to ThriveNewsWire.com. We cannot verify facts contained in submitted materials due to the volume of information. All submitted materials must adhere to our editorial guidelines and contain user-supplied contact details. <br />
                        <br />
                        We disclaim responsibility or liability for the accuracy, content, legality, reliability, or availability of information or material displayed on our website.<br />
                        <br />
                        We also disclaim any responsibility for harm resulting from accessing any material on the Internet through our service.<br />
                        You agree not to use the Service to provide material support to any organization designated as a foreign terrorist organization by the United States government.
                    </p>
                </div>
                <div>
                    <h3>
                        Limitations of Liability:
                    </h3>
                    <p>
                        We will not be liable for indirect, special, or consequential damages arising in connection with this Agreement or the Services, even if advised of the possibility of such damages. You agree to indemnify and hold us harmless from all claims, damages, and expenses relating to the use of the Services on your Site.
                    </p>
                </div>
                <div>
                    <h3>
                        Disclaimer of Warranties:
                    </h3>

                    <p>
                        The Services and all materials included are provided “as is,” with no warranties whatsoever. We disclaim all express, implied, and statutory warranties, including warranties of merchantability, fitness for a particular purpose, and non-infringement of proprietary rights.
                    </p>
                </div>
                <div>
                    <h3>
                        Links to Third-Party Sites:
                    </h3>
                    <p>
                        We do not control or endorse other websites linked through our Site. We disclaim responsibility for the legality, accuracy, or safety of content, products, or services on third-party sites.
                    </p>
                </div>
                <div>
                    <h3>
                        Billing:
                    </h3>
                    <p>
                        You must provide valid credit card information to use the Service. We reserve the right to modify fees and introduce new charges at any time. All fees are exclusive of taxes.
                    </p>
                </div>
                <div>
                    <h3>
                        Contract Agreements:
                    </h3>
                    <p>
                        All fully executed contracts and agreements will automatically renew unless canceled 7 days before the renewal date.

                    </p>
                </div>
                <div>
                    <h3>
                        Terms of Service Changes:
                    </h3>
                    <p>
                        We may modify the Services and these terms at any time without notice. Continued use of the Services constitutes acceptance of changes.
                    </p>
                </div>
                <div>
                    <h3>
                        ThriveNewsWire Service Pledge:
                    </h3>
                    <p>
                        We pledge to provide high-quality service and process press releases professionally and timely. We do not guarantee specific search engine rankings or media usage.
                    </p>
                </div>
                <div>
                    <h3>
                        Retraction of a Press Release:
                    </h3>
                    <p>
                        Once distributed, press releases cannot be retracted but can be deleted from the ThriveNewsWire website.
                    </p>
                </div>
                <div>
                    <h3>
                        Limitations on Scope of Use:
                    </h3>
                    <p>
                        Use of the ThriveNewsWire Site is limited to submission of news releases and articles for distribution via ThriveNewsWire connect, retrieving RSS feeds, and reading releases and marketing materials. Unauthorized use may result in termination of rights to use the Site.
                    </p>
                </div>
                <div>
                    <h3>
                        Miscellaneous:
                    </h3>
                    <p>
                        These Terms of Service are governed by U.S. Federal and State regulations. If any provision is deemed unenforceable, the remainder of the Terms of Service will remain in full force. All rights not expressly granted are reserved. You are responsible for all activity under your account and must comply with applicable laws.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Terms
